import {
  LoadingState,
  MonkActionType,
  MonkState,
  MonkUpdatedOneInspectionAdditionalDataAction,
  MonkUpdatedOnePricingAction,
  useMonkAppState,
  useMonkState,
} from '@monkvision/common';
import { useMonitoring } from '@monkvision/monitoring';
import { MonkApiConfig, useMonkApi } from '@monkvision/network';
import {
  AdditionalData,
  DamageType,
  Inspection,
  MonkEntityType,
  PricingV2RelatedItemType,
  ProgressStatus,
  Sight,
  VehiclePart,
  VehicleType,
} from '@monkvision/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewMode } from '../../components/InspectionReview/TabsSwitch';
import { getInspectionDisplayLocales, getInspectionSights } from '../../utils';
import { TeslaModel } from '../useTeslaInspectionList/types';
import {
  InteriorDamage,
  PartDetails,
  TESLA_WHEEL_BACK_LEFT,
  TESLA_WHEEL_BACK_RIGHT,
  TESLA_WHEEL_FRONT_LEFT,
  TESLA_WHEEL_FRONT_RIGHT,
} from './types';

function getWheelType(part: VehiclePart): VehiclePart[] {
  switch (part) {
    case VehiclePart.WHEEL_FRONT_LEFT:
      return TESLA_WHEEL_FRONT_LEFT;
    case VehiclePart.WHEEL_FRONT_RIGHT:
      return TESLA_WHEEL_FRONT_RIGHT;
    case VehiclePart.WHEEL_BACK_LEFT:
      return TESLA_WHEEL_BACK_LEFT;
    case VehiclePart.WHEEL_BACK_RIGHT:
      return TESLA_WHEEL_BACK_RIGHT;
    default:
      return [];
  }
}

function getVehicleModel(entities: MonkState, inspectionId: string) {
  const vehicleId = entities.inspections.find((i) => i.id === inspectionId)?.vehicle;
  const vehicleModel = entities.vehicles.find((v) => v.id === vehicleId)?.model;
  if ([TeslaModel.MODEL_3, TeslaModel.MODEL_S].includes(vehicleModel as TeslaModel)) {
    return VehicleType.SEDAN;
  }
  if ([TeslaModel.MODEL_Y, TeslaModel.MODEL_X].includes(vehicleModel as TeslaModel)) {
    return VehicleType.CUV;
  }
  return undefined;
}

export interface InspectionReviewParams {
  apiConfig: MonkApiConfig;
  inspectionId: string;
  loading: LoadingState;
}

export function useInspectionReview({ inspectionId, apiConfig, loading }: InspectionReviewParams) {
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.EXTERIOR);
  const [checkedParts, setCheckedParts] = useState<Array<VehiclePart>>([]);
  const [selectedPart, setSelectedPart] = useState<VehiclePart | undefined>();
  const [interiorIndexSelected, setInteriorIndexSelected] = useState(-1);
  const [currency, setCurrency] = useState('$');
  const [sights, setSights] = useState<Sight[]>([]);
  const { config } = useMonkAppState();

  const { state, dispatch } = useMonkState();
  const { t } = useTranslation();
  const { handleError } = useMonitoring();
  const {
    getInspection,
    createPricing,
    updatePricing,
    deletePricing,
    createDamage,
    deleteDamage,
    updateAdditionalData,
  } = useMonkApi(apiConfig);

  const inspection = useMemo(
    () => state.inspections.find((i) => i.id === inspectionId),
    [state.inspections],
  );

  const vehicleType = useMemo(() => getVehicleModel(state, inspectionId), [state, inspectionId]);

  const mappingPartsDetails = useMemo(() => {
    const parts = state.parts
      .filter((part) => part.inspectionId === inspectionId)
      .map((part) => {
        const damages = part.damages.reduce<DamageType[]>((acc, damageId) => {
          const damage = state.damages.find((value) => value.id === damageId)?.type;
          if (damage) {
            acc.push(damage);
          }
          return acc;
        }, []);
        const pricingObj = state.pricings.find((price) => price.relatedItemId === part.id);
        const pricing = pricingObj?.pricing;
        return { part: part.type, damages, pricing, isPriced: !!pricingObj };
      });
    const frontRight = parts.find((part) => part.part === VehiclePart.WHEEL_FRONT_RIGHT);
    const frontLeft = parts.find((part) => part.part === VehiclePart.WHEEL_FRONT_LEFT);
    const backRight = parts.find((part) => part.part === VehiclePart.WHEEL_BACK_RIGHT);
    const backLeft = parts.find((part) => part.part === VehiclePart.WHEEL_BACK_LEFT);
    parts.forEach((part) => {
      if (TESLA_WHEEL_FRONT_RIGHT.includes(part.part) && frontRight) {
        if (part.isPriced) {
          frontRight.isPriced = true;
        }
        if (frontRight.pricing && part.pricing) {
          frontRight.pricing += part.pricing;
        }
        if (frontRight.pricing === undefined) {
          frontRight.pricing = part.pricing;
        }
        frontRight.damages.push(...part.damages);
      }
      if (TESLA_WHEEL_FRONT_LEFT.includes(part.part) && frontLeft) {
        if (part.isPriced) {
          frontLeft.isPriced = true;
        }
        if (frontLeft.pricing && part.pricing) {
          frontLeft.pricing += part.pricing;
        }
        if (frontLeft.pricing === undefined) {
          frontLeft.pricing = part.pricing;
        }
        frontLeft.damages.push(...part.damages);
      }
      if (TESLA_WHEEL_BACK_RIGHT.includes(part.part) && backRight) {
        if (part.isPriced) {
          backRight.isPriced = true;
        }
        if (backRight.pricing && part.pricing) {
          backRight.pricing += part.pricing;
        }
        if (backRight.pricing === undefined) {
          backRight.pricing = part.pricing;
        }
        backRight.damages.push(...part.damages);
      }
      if (TESLA_WHEEL_BACK_LEFT.includes(part.part) && backLeft) {
        if (part.isPriced) {
          backLeft.isPriced = true;
        }
        if (backLeft.pricing && part.pricing) {
          backLeft.pricing += part.pricing;
        }
        if (backLeft.pricing === undefined) {
          backLeft.pricing = part.pricing;
        }
        backLeft.damages.push(...part.damages);
      }
    });
    return parts;
  }, [state]);

  const mappingPartPrice = mappingPartsDetails.map((part) => {
    return { part: part.part, pricing: part.pricing ?? 0, isPriced: part.isPriced };
  });

  const interiorDamageDetails: InteriorDamage[] = useMemo(() => {
    const otherDamages = inspection?.additionalData?.['other_damages'];
    return otherDamages ? (otherDamages as InteriorDamage[]) : [];
  }, [state]);

  const selectedPartDetails: PartDetails | undefined = useMemo(() => {
    if (!selectedPart && viewMode === ViewMode.INTERIOR) {
      return undefined;
    }
    const partDetails = mappingPartsDetails.find((part) => part.part === selectedPart);
    const partToModify = state.parts
      .filter((part) => part.inspectionId === inspectionId)
      .find((part) => part.type === selectedPart);
    const isDamaged = !!partToModify?.damages.length || !!partDetails?.isPriced;
    const damagesType = partDetails?.damages ?? [];
    const pricing = mappingPartPrice.find((value) => value.part === selectedPart)?.pricing ?? 0;
    return {
      part: selectedPart as VehiclePart,
      isDamaged,
      damagesType,
      pricing,
      isPriced: partDetails?.isPriced,
    };
  }, [mappingPartsDetails, selectedPart]);

  const selectedInteriorDamageDetails = useMemo(() => {
    if (!interiorDamageDetails || (selectedPart && viewMode === ViewMode.EXTERIOR)) {
      return undefined;
    }
    return !interiorDamageDetails.length || interiorIndexSelected === -1
      ? undefined
      : interiorDamageDetails.at(interiorIndexSelected);
  }, [state, selectedPart, interiorIndexSelected, interiorDamageDetails]);

  const handleOnInteriorConfirm = useCallback(
    (interiorDamage: InteriorDamage) => {
      const callback = (existingData?: AdditionalData) => {
        const newAdditionalData = {
          area: interiorDamage?.area,
          damage_type: interiorDamage?.damage_type,
          repair_cost: interiorDamage?.repair_cost,
        };
        if (interiorIndexSelected !== -1 || !existingData?.['other_damages']) {
          const cpy = [...interiorDamageDetails];
          cpy[interiorIndexSelected] = newAdditionalData;
          return { ...existingData, other_damages: cpy };
        }
        return {
          ...existingData,
          other_damages: [
            ...(existingData?.['other_damages'] as unknown as AdditionalData[]),
            newAdditionalData,
          ],
        };
      };

      const action: MonkUpdatedOneInspectionAdditionalDataAction = {
        type: MonkActionType.UPDATED_ONE_INSPECTION_ADDITIONAL_DATA,
        payload: {
          inspectionId,
          additionalData: callback(inspection?.additionalData),
        },
      };
      dispatch(action);
      updateAdditionalData({ id: inspectionId, callback });
      setViewMode(ViewMode.INTERIOR);
      setInteriorIndexSelected(-1);
    },
    [interiorIndexSelected, interiorDamageDetails],
  );

  const handleOnExteriorConfirm = (editedDamage: PartDetails) => {
    const partToUpdate = state.parts
      .filter((value) => value.inspectionId === inspectionId)
      .find((part) => part.type === selectedPart);

    const pricingToModify = state.pricings
      .filter((value) => value.inspectionId === inspectionId)
      .find((pricing) => pricing.relatedItemId === partToUpdate?.id);

    if (partToUpdate?.type.includes('wheel')) {
      const wheelType: VehiclePart[] = getWheelType(partToUpdate.type);
      const partIdWheelToDelete = state.parts
        .filter((p) => p.inspectionId === inspectionId && wheelType.includes(p.type))
        .map((p) => p.id);
      state.parts
        .filter((p) => p.inspectionId === inspectionId && wheelType.includes(p.type))
        .forEach((part) => {
          part.damages.forEach((damageId) => {
            deleteDamage({ id: inspectionId, damageId });
          });
        });
      state.pricings
        .filter(
          (value) =>
            value.inspectionId === inspectionId &&
            value.relatedItemId &&
            partIdWheelToDelete.includes(value.relatedItemId),
        )
        .forEach((pricing) => {
          deletePricing({ id: inspectionId, pricingId: pricing.id });
        });
    }

    if (!editedDamage.isDamaged) {
      try {
        partToUpdate?.damages.forEach((damageId) => {
          deleteDamage({ id: inspectionId, damageId });
        });
        if (pricingToModify) {
          deletePricing({ id: inspectionId, pricingId: pricingToModify?.id });
        }
      } catch (e) {
        handleError(e);
        loading.onError();
      }
    }

    if (editedDamage.isDamaged) {
      if (pricingToModify && editedDamage.pricing !== undefined) {
        const action: MonkUpdatedOnePricingAction = {
          type: MonkActionType.UPDATED_ONE_PRICING,
          payload: {
            pricing: {
              entityType: MonkEntityType.PRICING,
              id: pricingToModify.id,
              inspectionId,
              relatedItemType: PricingV2RelatedItemType.PART,
              pricing: editedDamage.pricing,
            },
          },
        };
        dispatch(action);
        updatePricing({
          id: inspectionId,
          pricingId: pricingToModify.id,
          price: editedDamage.pricing,
        });
      }
      if (!pricingToModify && editedDamage.pricing) {
        createPricing({
          id: inspectionId,
          pricing: {
            pricing: editedDamage.pricing,
            type: PricingV2RelatedItemType.PART,
            vehiclePart: editedDamage.part,
          },
        })
          .then(() => {
            if (!partToUpdate) {
              getInspection({ id: inspectionId });
            }
          })
          .catch(() => {
            loading.onError(t('inspectionReview.errors.notCompleted'));
          });
      }
      const damagesFilteredByPartSelected = state.damages
        .filter((value) => value.inspectionId === inspectionId)
        .filter((damage) => partToUpdate?.damages.includes(damage.id));
      damagesFilteredByPartSelected.forEach((damage) => {
        if (!editedDamage.damagesType.includes(damage.type)) {
          deleteDamage({ id: inspectionId, damageId: damage.id });
        }
      });

      editedDamage.damagesType.forEach((damage) => {
        if (!damagesFilteredByPartSelected.map((value) => value.type).includes(damage)) {
          createDamage({ id: inspectionId, damageType: damage, vehiclePart: editedDamage.part });
        }
      });
    }

    const newPartsValidated = selectedPart ? [...checkedParts, selectedPart] : checkedParts;
    setCheckedParts(newPartsValidated);
    setSelectedPart(undefined);
  };

  const handleExteriorCancel = () => setSelectedPart(undefined);

  const handleInteriorCancel = () => {
    setViewMode(ViewMode.INTERIOR);
    setInteriorIndexSelected(-1);
  };

  const handleAddDamage = () => {
    setViewMode(ViewMode.INTERIOR_FORM);
    setInteriorIndexSelected(interiorDamageDetails.length);
  };

  const handleDeleteIntDamage = useCallback(
    (index: number) => {
      const callback = (existingData?: AdditionalData) => {
        return {
          ...existingData,
          other_damages: interiorDamageDetails.filter((_, idx) => idx !== index),
        };
      };
      const action: MonkUpdatedOneInspectionAdditionalDataAction = {
        type: MonkActionType.UPDATED_ONE_INSPECTION_ADDITIONAL_DATA,
        payload: {
          inspectionId,
          additionalData: callback(inspection?.additionalData),
        },
      };
      dispatch(action);
      updateAdditionalData({ id: inspectionId, callback });
    },
    [interiorDamageDetails],
  );

  const handleOnUpdateIntDamage = (index: number) => {
    setInteriorIndexSelected(index);
    setViewMode(ViewMode.INTERIOR_FORM);
  };

  useEffect(() => {
    loading.start();

    const fetchInspection = async () => {
      if (!inspectionId) {
        loading.onSuccess();
        return;
      }
      const fetchedInspection = await getInspection({
        id: inspectionId,
      }).catch(() => {
        throw new Error(t('inspectionReview.errors.inspectionId'));
      });
      const insp = fetchedInspection.entities.inspections.find(
        (i) => i.id === inspectionId,
      ) as Inspection;
      const sightsIds = fetchedInspection.entities.images
        .map((image) => image.sightId)
        .filter((id) => id !== undefined) as string[];
      setSights(getInspectionSights(insp, config, sightsIds));
      if (
        fetchedInspection.entities.tasks.some(
          (task) =>
            task.inspectionId === inspectionId && task.status === ProgressStatus.NOT_STARTED,
        )
      ) {
        throw new Error(t('inspectionReview.errors.notCompleted'));
      }
      setCurrency(getInspectionDisplayLocales(insp).currency);
      fetchedInspection.entities.images.forEach((image) => {
        const imgPath = new Image();
        const imgRenderedOutput = new Image();
        imgPath.src = image.path;
        const renderedOutput = fetchedInspection.entities.renderedOutputs.find(
          (item) => item.id === image.renderedOutputs[1],
        );
        imgRenderedOutput.src = renderedOutput?.path ?? '';
      });
      loading.onSuccess();
    };

    fetchInspection()
      .then(loading.onSuccess)
      .catch((e) => {
        const error = e as Error;
        loading.onError(error.message);
      });
  }, [inspectionId]);

  return {
    sights,
    selectedPart,
    interiorDamageDetails,
    selectedInteriorDamageDetails,
    viewMode,
    selectedPartDetails,
    mappingPartPrice,
    checkedParts,
    currency,
    vehicleType,
    setViewMode,
    setSelectedPart,
    handleOnUpdateIntDamage,
    handleOnInteriorConfirm,
    handleOnExteriorConfirm,
    handleInteriorCancel,
    handleExteriorCancel,
    handleDeleteIntDamage,
    handleAddDamage,
  };
}
