import { ActionIcon, Button, Checkbox, Menu, TextInput } from '@mantine/core';
import { IconDownload, IconSearch, IconX } from '@tabler/icons-react';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { LoadingState } from '@monkvision/common';
import { UseTeslaInspectionListFiltersResult } from '../../hooks';
import styles from './InspectionListFilters.module.css';
import { STATUS_TRANSLATION_KEYS } from '../DashboardDrawer';
import { TeslaInspectionStatus } from '../../hooks/useTeslaInspectionList/types';
import { TeslaInspectionListFilters } from '../../hooks/useTeslaInspectionListFilters/types';
import { DateFilter } from '../DateFilter';

const DEFAULT_STATUS_FILTERS = Object.values(TeslaInspectionStatus).reduce<
  Record<TeslaInspectionStatus, boolean>
>((prev, value) => ({ ...prev, [value]: false }), {} as Record<TeslaInspectionStatus, boolean>);

export interface InspectionListFiltersProps
  extends Pick<
    UseTeslaInspectionListFiltersResult,
    'filterByDate' | 'filterBySearchInput' | 'filterByStatuses'
  > {
  title: string;
  filters: TeslaInspectionListFilters;
  showStatusFilter: boolean;
  isListLoading: boolean;
  onExportToCsv: () => void;
  exportToCsvLoading: LoadingState;
}

function areSearchInputEquals(a: string | null | undefined, b: string | null | undefined): boolean {
  return !a || !b ? !a === !b : a === b;
}

export function InspectionListFilters({
  title,
  filters,
  showStatusFilter,
  filterByDate,
  filterBySearchInput,
  filterByStatuses,
  isListLoading,
  onExportToCsv,
  exportToCsvLoading,
}: InspectionListFiltersProps) {
  const statusFiltersCurrentValue = useMemo(
    () =>
      Object.values(TeslaInspectionStatus).reduce(
        (prev, curr) => ({ ...prev, [curr]: !!filters.statuses?.includes(curr) }),
        {} as Record<TeslaInspectionStatus, boolean>,
      ),
    [filters?.statuses],
  );
  const [statusFilters, setStatusFilters] = useState(statusFiltersCurrentValue);
  const [searchInput, setSearchInput] = useState(filters.searchInput);
  const [filtersMenuOpened, setFiltersMenuOpened] = useState(false);
  const { t } = useTranslation();

  const applyFiltersAvailable = useMemo(
    () =>
      Object.values(TeslaInspectionStatus).some(
        (status) => statusFiltersCurrentValue[status] !== statusFilters[status],
      ),
    [statusFiltersCurrentValue, statusFilters],
  );

  const handleMenuClosed = () => {
    setStatusFilters(statusFiltersCurrentValue);
  };

  const closeFiltersMenu = () => {
    setFiltersMenuOpened(false);
    handleMenuClosed();
  };

  const clearSearchInput = () => {
    setSearchInput('');
    filterBySearchInput(null);
  };

  const applyFiltersMenu = () => {
    const checkedValues = Object.values(TeslaInspectionStatus).filter(
      (status) => statusFilters[status],
    );
    filterByStatuses(checkedValues.length > 0 ? checkedValues : null);
    setFiltersMenuOpened(false);
  };

  const selectStatusFilter = (status: TeslaInspectionStatus) => {
    setStatusFilters((value) => ({
      ...DEFAULT_STATUS_FILTERS,
      [status]: !value[status],
    }));
  };

  useEffect(() => {
    if (filters.statuses === null) {
      applyFiltersMenu();
    }
  }, [filters?.statuses]);

  return (
    <div className={styles['container']}>
      <div className={styles['title']}>{title}</div>
      <div className={styles['filters']}>
        <div className={styles['left']}>
          <TextInput
            icon={<IconSearch />}
            rightSection={
              searchInput && (
                <ActionIcon variant='transparent' color='dark' onClick={clearSearchInput}>
                  <IconX size={18} />
                </ActionIcon>
              )
            }
            variant='unstyled'
            placeholder={t('inspectionList.searchPlaceHolder')}
            classNames={{
              root: styles['inputRoot'],
              wrapper: styles['inputWrapper'],
              input: clsx(
                searchInput &&
                  areSearchInputEquals(searchInput, filters.searchInput) &&
                  styles['inputActive'],
              ),
            }}
            value={searchInput ?? ''}
            onChange={(e) => setSearchInput(e.target.value)}
            disabled={isListLoading}
          />
          <Button
            variant='filled'
            color='dark'
            classNames={{ root: styles['middleButton'] }}
            disabled={isListLoading || areSearchInputEquals(searchInput, filters.searchInput)}
            onClick={() => filterBySearchInput(searchInput)}
          >
            {t('inspectionList.search')}
          </Button>
          {showStatusFilter && (
            <Menu
              shadow='md'
              opened={filtersMenuOpened}
              onChange={setFiltersMenuOpened}
              closeOnItemClick={false}
              onClose={handleMenuClosed}
            >
              <Menu.Target>
                <Button
                  variant={filters.statuses ? 'filled' : 'outline'}
                  color='dark'
                  classNames={{ root: styles['middleButton'] }}
                  disabled={isListLoading}
                >
                  {t('inspectionList.status')}
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>{t('inspectionList.filterByStatus')}</Menu.Label>
                {Object.values(TeslaInspectionStatus).map((status) => (
                  <Menu.Item key={status} onClick={() => selectStatusFilter(status)}>
                    <Checkbox
                      label={t(STATUS_TRANSLATION_KEYS[status])}
                      checked={statusFilters[status]}
                      classNames={{ root: styles['statusCheckbox'] }}
                      color='dark'
                    />
                  </Menu.Item>
                ))}
                <Menu.Divider />
                <div className={styles['statusesButtons']}>
                  <Button variant='subtle' color='dark' onClick={closeFiltersMenu}>
                    {t('inspectionList.cancel')}
                  </Button>
                  <Button
                    variant='filled'
                    color='dark'
                    onClick={applyFiltersMenu}
                    disabled={!applyFiltersAvailable}
                  >
                    {t('inspectionList.apply')}
                  </Button>
                </div>
              </Menu.Dropdown>
            </Menu>
          )}
          <DateFilter filters={filters} filterByDate={filterByDate} isListLoading={isListLoading} />
        </div>
        <div className={styles['right']}>
          <Button
            variant='filled'
            color='dark'
            leftIcon={<IconDownload size={14} />}
            classNames={{ root: styles['middleButton'] }}
            disabled={isListLoading || exportToCsvLoading.isLoading}
            loading={exportToCsvLoading.isLoading}
            onClick={onExportToCsv}
          >
            {t('inspectionList.exportCsv')}
          </Button>
        </div>
      </div>
    </div>
  );
}
