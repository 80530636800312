import { useEffect, useState } from 'react';
import { InteriorDamage, PartDetails } from '../../../../hooks';

export interface InteriorModalParams {
  selectedPartDetails?: PartDetails;
  interiorDamage?: InteriorDamage;
}

export function useInteriorModal({ selectedPartDetails, interiorDamage }: InteriorModalParams) {
  const [newInteriorDamage, setNewInteriorDamage] = useState<InteriorDamage | undefined>(
    interiorDamage,
  );

  const handleAreaChange = (area: string) => {
    if (!newInteriorDamage) {
      return;
    }
    const updatedInteriorDamage: InteriorDamage = {
      ...newInteriorDamage,
      area,
    };
    setNewInteriorDamage(updatedInteriorDamage);
  };

  const handleInteriorDamageTypeChange = (damageType: string) => {
    if (!newInteriorDamage) {
      return;
    }
    const updatedInteriorDamage: InteriorDamage = {
      ...newInteriorDamage,
      damage_type: damageType,
    };
    setNewInteriorDamage(updatedInteriorDamage);
  };

  const handleInteriorDeductionChange = (deduction: number | null) => {
    if (!newInteriorDamage) {
      return;
    }
    const updatedInteriorDamage: InteriorDamage = {
      ...newInteriorDamage,
      repair_cost: deduction,
    };
    setNewInteriorDamage(updatedInteriorDamage);
  };

  useEffect(() => {
    setNewInteriorDamage(interiorDamage ?? { area: '', damage_type: '', repair_cost: 0, index: 0 });
  }, [selectedPartDetails, interiorDamage]);

  return {
    newInteriorDamage,
    handleAreaChange,
    handleInteriorDamageTypeChange,
    handleInteriorDeductionChange,
  };
}
