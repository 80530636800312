import { getEnvOrThrow } from '@monkvision/common';
import { Auth0ContextInterface, Auth0Provider, AuthorizationParams } from '@auth0/auth0-react';
import { createContext, PropsWithChildren } from 'react';
import { decodeMonkJwt, MonkAuthHandle, useAuth } from '@monkvision/network';
import { AppRegion } from './contexts';

const AUTH_CLIENT_ID_EU = getEnvOrThrow('REACT_APP_AUTH_CLIENT_ID_EU');
const AUTH_CLIENT_ID_US = getEnvOrThrow('REACT_APP_AUTH_CLIENT_ID_US');
const DOMAIN = getEnvOrThrow('REACT_APP_AUTH_DOMAIN');
const AUTHORIZATION_PARAMS: AuthorizationParams = {
  redirect_uri: window.location.origin,
  audience: getEnvOrThrow('REACT_APP_AUTH_AUDIENCE'),
  prompt: 'login',
};

const AUTH_CONTEXT_EU = createContext(null as unknown as Auth0ContextInterface);
const AUTH_CONTEXT_US = createContext(null as unknown as Auth0ContextInterface);

export function AuthProvider({ children }: PropsWithChildren<unknown>) {
  return (
    <Auth0Provider
      domain={DOMAIN}
      clientId={AUTH_CLIENT_ID_EU}
      authorizationParams={AUTHORIZATION_PARAMS}
      context={AUTH_CONTEXT_EU}
    >
      <Auth0Provider
        domain={DOMAIN}
        clientId={AUTH_CLIENT_ID_US}
        authorizationParams={AUTHORIZATION_PARAMS}
        context={AUTH_CONTEXT_US}
      >
        {children}
      </Auth0Provider>
    </Auth0Provider>
  );
}

export function useRegionalAuth(): Record<AppRegion, MonkAuthHandle> {
  const eu = useAuth({ context: AUTH_CONTEXT_EU });
  const us = useAuth({ context: AUTH_CONTEXT_US });

  return {
    [AppRegion.EU]: eu,
    [AppRegion.US]: us,
  };
}

export function getAuthTokenRegion(token: string | null): AppRegion | null {
  if (!token) {
    return null;
  }
  const decoded = decodeMonkJwt(token);
  if (decoded.azp === AUTH_CLIENT_ID_EU) {
    return AppRegion.EU;
  }
  if (decoded.azp === AUTH_CLIENT_ID_US) {
    return AppRegion.US;
  }
  return null;
}
