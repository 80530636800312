import { useLoadingState } from '@monkvision/common';
import { Spinner } from '@monkvision/common-ui-web';
import { MonkApiConfig } from '@monkvision/network';
import { VehiclePart, VehicleType } from '@monkvision/types';
import { DebouncedState } from 'use-debounce';
import { TabsSwitch, ViewMode } from './TabsSwitch';
import { DamageManipulator, InteriorManipulatorModal } from './DamageManipulator';
import { InspectionGallery } from './InspectionGallery';
import { styles } from './InspectionReview.styles';
import { InteriorDamageTable } from './InteriorDamageTable';
import { useInspectionReviewStyles } from './useInspectionReviewStyle';
import { Vehicle360 } from './Vehicle360';
import { GeneratePDFButton } from './GeneratePDFButton';
import { useSetOriginalDeductions, useInspectionReview } from '../../hooks';
import { TeslaInspectionStatus } from '../../hooks/useTeslaInspectionList/types';

export interface InspectionReviewProps {
  inspectionId: string;
  apiConfig: MonkApiConfig;
  onStatusChange: DebouncedState<(inspectionId: string, value: TeslaInspectionStatus) => void>;
}

export function InspectionReview({
  inspectionId,
  apiConfig,
  onStatusChange,
}: InspectionReviewProps) {
  const loading = useLoadingState(true);
  const style = useInspectionReviewStyles();
  const {
    sights,
    selectedPart,
    interiorDamageDetails,
    selectedInteriorDamageDetails,
    viewMode,
    mappingPartPrice,
    selectedPartDetails,
    checkedParts,
    currency,
    vehicleType,
    setViewMode,
    setSelectedPart,
    handleOnUpdateIntDamage,
    handleOnExteriorConfirm,
    handleOnInteriorConfirm,
    handleExteriorCancel,
    handleInteriorCancel,
    handleDeleteIntDamage,
    handleAddDamage,
  } = useInspectionReview({
    inspectionId,
    apiConfig,
    loading,
  });
  useSetOriginalDeductions({ inspectionId, apiConfig });

  return (
    <div style={styles['container']}>
      {loading.isLoading && <Spinner primaryColor='gray' size={80} />}
      {loading.error && <div style={{ color: 'black' }}>{loading.error}</div>}
      {!loading.isLoading && !loading.error && (
        <div style={styles['content']}>
          <div style={styles['header']}>
            <div style={styles['menu']}>
              <TabsSwitch
                mode={viewMode}
                onTabChange={(mode) => {
                  setViewMode(mode);
                  setSelectedPart(
                    [ViewMode.INTERIOR, ViewMode.INTERIOR_FORM].includes(mode)
                      ? VehiclePart.IGNORE
                      : undefined,
                  );
                }}
              />
              <div style={styles['generatePDFContainer']}>
                <GeneratePDFButton inspectionId={inspectionId} onStatusChange={onStatusChange} />
              </div>
            </div>
          </div>
          <div style={style.vehicle360Style}>
            <Vehicle360
              partSelected={selectedPart}
              priceByPart={mappingPartPrice}
              vehicleType={vehicleType ?? VehicleType.SEDAN}
              enableMultiplePartSelection={false}
              validedParts={checkedParts}
              hidden={
                [ViewMode.INTERIOR, ViewMode.INTERIOR_FORM].includes(viewMode) || !!selectedPart
              }
              onPartsSelected={(selectedParts: VehiclePart[]) => {
                setSelectedPart(selectedParts.at(0));
              }}
            />
            {viewMode === ViewMode.INTERIOR && (
              <InteriorDamageTable
                currency={currency}
                damages={interiorDamageDetails}
                onAddDamage={handleAddDamage}
                onEdit={handleOnUpdateIntDamage}
                onDelete={handleDeleteIntDamage}
              />
            )}
            {viewMode === ViewMode.INTERIOR_FORM && (
              <InteriorManipulatorModal
                interiorDamage={selectedInteriorDamageDetails}
                currency={currency}
                onConfirm={handleOnInteriorConfirm}
                onCancel={handleInteriorCancel}
              />
            )}

            {viewMode === ViewMode.EXTERIOR && (
              <DamageManipulator
                isVisible={!!selectedPart}
                currency={currency}
                selectedPartDetails={selectedPartDetails}
                handleExteriorConfirm={handleOnExteriorConfirm}
                onCancel={handleExteriorCancel}
              />
            )}
          </div>
          <div style={style.galleryStyle}>
            <InspectionGallery
              inspectionId={inspectionId}
              apiConfig={apiConfig}
              captureMode={false}
              enableAddDamage={false}
              showBackButton={true}
              reportMode={true}
              filterByPart={selectedPart}
              filterInterior={[ViewMode.INTERIOR, ViewMode.INTERIOR_FORM].includes(viewMode)}
              sights={sights}
            />
          </div>
        </div>
      )}
    </div>
  );
}
