import { createContext, PropsWithChildren, useContext, useState } from 'react';

export enum AppRegion {
  EU = 'eu',
  US = 'us',
}

const APP_REGION_FLAGS: Record<AppRegion, string> = {
  [AppRegion.EU]: '🇪🇺',
  [AppRegion.US]: '🇺🇸',
};

export interface AppRegionContextHandlers {
  region: AppRegion | null;
  setRegion: (region: AppRegion) => void;
}

const AppRegionContext = createContext<AppRegionContextHandlers>({
  region: null,
  setRegion: () => {},
});

export function AppRegionProvider({ children }: PropsWithChildren<unknown>) {
  const [region, setRegion] = useState<AppRegion | null>(null);

  return (
    <AppRegionContext.Provider value={{ region, setRegion }}>{children}</AppRegionContext.Provider>
  );
}

export function useAppRegion(): AppRegionContextHandlers {
  return useContext(AppRegionContext);
}

export function getAppRegionFlag(region: AppRegion | null): string | null {
  return region ? APP_REGION_FLAGS[region] : null;
}
