import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import { useIsMounted, useLoadingState, useMonkAppState } from '@monkvision/common';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { decodeMonkJwt, isTokenExpired } from '@monkvision/network';
import { useMonitoring } from '@monkvision/monitoring';
import { useAnalytics } from '@monkvision/analytics';
import { IconLogout } from '@tabler/icons-react';
import { AppRegion, useAppRegion } from '../../contexts';
import styles from './LoginPage.module.css';
import { Page } from '../pages';
import { useRegionalAuth } from '../../auth';

function getLoginErrorMessage(err: unknown): string {
  if (err instanceof Error) {
    if (err.message === 'Popup closed') {
      return 'login.errors.popup-closed';
    }
  }
  return 'login.errors.unknown';
}

function isTokenAuthorized(token: string): boolean {
  const decoded = decodeMonkJwt(token);
  return !!decoded.permissions && decoded.permissions.length > 0;
}

export function LoginPage() {
  const { region, setRegion } = useAppRegion();
  const { authToken } = useMonkAppState();
  const regionalAuth = useRegionalAuth();
  const { t } = useTranslation();
  const loading = useLoadingState();
  const analytics = useAnalytics();
  const { handleError, setUserId } = useMonitoring();
  const navigate = useNavigate();
  const isMounted = useIsMounted();

  const isExpired = useMemo(() => authToken && isTokenExpired(authToken), [authToken]);

  useEffect(() => {
    if (authToken) {
      if (!isTokenAuthorized(authToken)) {
        loading.onError('login.errors.insufficient-authorization');
      } else if (!isExpired) {
        navigate(Page.DASHBOARD);
      }
    }
  }, [authToken, isExpired]);

  const regionalLogin = async (appRegion: AppRegion) => {
    loading.start();
    try {
      const token = await regionalAuth[appRegion].login();
      navigate(Page.DASHBOARD);
      if (isMounted()) {
        loading.onSuccess();
        const userId = token ? decodeMonkJwt(token) : undefined;
        if (userId?.sub) {
          setUserId(userId.sub);
          analytics.setUserProperties({ authToken: userId.sub });
        }
      }
    } catch (err) {
      if (isMounted()) {
        const message = getLoginErrorMessage(err);
        loading.onError(message);
      }
      handleError(err);
    }
  };

  const handleEULogIn = async () => {
    setRegion(AppRegion.EU);
    regionalLogin(AppRegion.EU).catch(() => {});
  };

  const handleUSLogIn = async () => {
    setRegion(AppRegion.US);
    regionalLogin(AppRegion.US).catch(() => {});
  };

  return (
    <div className={styles['container']}>
      {isExpired && <div className={styles['errorMessage']}>{t('login.errors.token-expired')}</div>}
      {loading.error && <div className={styles['errorMessage']}>{t(loading.error as string)}</div>}
      <div className={styles['buttonsContainer']}>
        {authToken && (
          <Button
            onClick={regionalAuth[region ?? AppRegion.EU].logout}
            color='red'
            rightIcon={<IconLogout size={14} />}
          >
            {t('login.logout')}
          </Button>
        )}
        {!authToken && (
          <>
            <Button
              color='dark'
              leftIcon='🇪🇺'
              onClick={handleEULogIn}
              className={styles['loginButton']}
              loading={loading.isLoading}
            >
              {t('login.eu')}
            </Button>
            <Button
              color='dark'
              leftIcon='🇺🇸'
              onClick={handleUSLogIn}
              className={styles['loginButton']}
              loading={loading.isLoading}
            >
              {t('login.us')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
