import {
  LoadingState,
  useAsyncEffect,
  useLoadingState,
  useMonkAppState,
  useObjectMemo,
} from '@monkvision/common';
import { useMonitoring } from '@monkvision/monitoring';
import { useMonkApi } from '@monkvision/network';
import { useState } from 'react';
import { OwnershipFilter, TeslaInspectionStatus } from '../useTeslaInspectionList/types';

export interface UseTeslaInspectionCountByStatusResult {
  counts: Record<TeslaInspectionStatus | 'all', number> | null;
  loading: LoadingState;
}

export interface TeslaInspectionCountByStatusParams {
  status: TeslaInspectionStatus | null;
  ownershipFilter: OwnershipFilter;
  triggerEffect: boolean;
}

const ALL_STATUSES: (TeslaInspectionStatus | 'all')[] = [
  'all',
  ...Object.values(TeslaInspectionStatus),
];

export function useTeslaInspectionCountByStatus({
  status,
  ownershipFilter,
  triggerEffect,
}: TeslaInspectionCountByStatusParams): UseTeslaInspectionCountByStatusResult {
  const [counts, setCounts] = useState<Record<TeslaInspectionStatus | 'all', number> | null>(null);
  const loading = useLoadingState();
  const { handleError } = useMonitoring();
  const { config, authToken } = useMonkAppState();
  const apiConfig = {
    authToken: authToken ?? '',
    apiDomain: config.apiDomain,
    thumbnailDomain: config.thumbnailDomain,
  };
  const { getAllInspectionsCount } = useMonkApi(apiConfig);

  useAsyncEffect(
    () => {
      if (!counts) {
        loading.start();
      }
      const inspectionsPromise = ALL_STATUSES.map((value) =>
        getAllInspectionsCount({
          filters: {
            ...(value === 'all' ? {} : { customer_status: value }),
            ownership_filter: ownershipFilter,
          },
        }).then(({ count }) => ({ status: value, count })),
      );
      return Promise.all(inspectionsPromise);
    },
    [status, triggerEffect],
    {
      onResolve: (responses) => {
        const newCounts = responses.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.status]: curr.count,
          }),
          {} as Record<TeslaInspectionStatus | 'all', number>,
        );
        setCounts(newCounts);
        loading.onSuccess();
      },
      onReject: (err) => {
        handleError(err);
        loading.onError(err);
      },
    },
  );

  return useObjectMemo({ counts, loading });
}
