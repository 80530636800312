import { Image, RenderedOutput } from '@monkvision/types';
import { useMonkState, useMonkTheme } from '@monkvision/common';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch';
import { styles } from './ImageDetailedView.styles';

/**
 * Props accepted by the ImageDetailedView component.
 */
export type ImageDetailedViewProps = {
  /**
   * The image to display the details of.
   */
  image: Image;
  /**
   * The language to be used by the component.
   *
   * @default en
   */
  showDamage: boolean;
  lang?: string | null;
  /**
   * Boolean indicating if the gallery button (used to go back to the gallery if this component is used inside the
   * gallery) must be displayed or not.
   *
   * @default true
   */
  showGalleryButton?: boolean;
  /**
   * Callback called when the user presses the close button.
   */
  onClose?: () => void;
  /**
   * Callback called when the user presses the gallery button if it is displayed.
   */
  onPrev?: () => void;
  onNext?: () => void;
  onNavigateToGallery?: () => void;

  onShowDamage?: () => void;

  reportMode?: boolean;
} & (
  | {
      /**
       * Boolean indicating if this component is displayed in "capture" mode. Capture mode enables features such as
       * compliance, retakes, navigating to capture etc. Set this prop to `true` if your user is currently capturing
       * pictures for their inspection.
       *
       * @default false
       */
      captureMode: true;
      /**
       * Boolean indicating if the capture button must be displayed or not. This prop can only be specified if
       * `captureMode` is set to true.
       *
       * @default true
       */
      showCaptureButton?: boolean;
      /**
       * Callback called when the user presses the capture button. This prop can only be specified if `captureMode` is
       * set to true.
       */
      onNavigateToCapture?: () => void;
      /**
       * Callback called when the user presses the retake button. This prop can only be specified if `captureMode` is
       * set to true.
       */
      onRetake?: () => void;
    }
  | {
      /**
       * Boolean indicating if this component is displayed in "capture" mode. Capture mode enables features such as
       * compliance, retakes, navigating to capture etc. Set this prop to `true` if your user is currently capturing
       * pictures for their inspection.
       *
       * @default false
       */
      captureMode: false;
    }
);

function getBackgroundImage(
  image: Image,
  renderedOutputs: RenderedOutput[],
  reportMode: boolean | undefined,
  showDamage: boolean,
): string {
  if (!reportMode) {
    return `url(${image.path})`;
  }

  const renderedOutput = renderedOutputs.find(
    (item) =>
      item.additionalData?.['description'] === 'rendering of detected damages' &&
      image.renderedOutputs.includes(item.id),
  );
  return showDamage ? renderedOutput?.path || image.path : image.path;
}

export function useImageDetailedView(props: ImageDetailedViewProps) {
  const ref = useRef<ReactZoomPanPinchContentRef>(null);
  const [isMouseOver, setIsMouseOver] = useState(true);
  const [cursorStyle, setCursorStyle] = useState('grab');
  const { state } = useMonkState();

  const handleMouseDown = () => {
    setCursorStyle('grabbing');
  };

  const handleMouseUp = () => {
    setCursorStyle('grab');
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.metaKey) {
      setCursorStyle('zoom-in');
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (!event.metaKey) {
      setCursorStyle('grab');
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    const target = event.target as Element;
    const elementUnderMouse = target.closest('.parent');
    if (elementUnderMouse && elementUnderMouse.className === 'parent') {
      setIsMouseOver(true);
    } else {
      setIsMouseOver(false);
    }
  };

  const backgroundImage = useMemo(
    () =>
      getBackgroundImage(props.image, state.renderedOutputs, props.reportMode, props.showDamage),
    [props.image, state.renderedOutputs, props.showDamage],
  );

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    ref.current?.resetTransform(0);
  }, [props.image]);

  return { backgroundImage, origin, isMouseOver, handleMouseDown, handleMouseUp, ref, cursorStyle };
}

export function useImageDetailedViewStyles(props: ImageDetailedViewProps, isMouseOver: boolean) {
  const { palette } = useMonkTheme();

  let rightContainerJustifyContent = 'start';
  if (props.captureMode) {
    rightContainerJustifyContent = props.showGalleryButton ? 'space-between' : 'end';
  }

  return {
    mainContainerStyle: {
      ...styles['mainContainer'],
    },
    overlayStyle: {
      ...styles['overlay'],
    },
    imageContainerStyle: {
      ...styles['imageContainer'],
    },
    topContainerStyle: {
      ...styles['topContainer'],
    },
    leftContainerStyle: {
      ...styles['leftContainer'],
      opacity: isMouseOver ? '1' : '0',
    },
    overlayContainerStyle: {
      ...styles['overlayContainer'],
      opacity: isMouseOver ? '1' : '0',
    },
    rightContainerStyle: {
      ...styles['rightContainer'],
      justifyContent: rightContainerJustifyContent,
      opacity: isMouseOver ? '1' : '0',
    },
    closeButton: {
      primaryColor: palette.secondary.xdark,
      secondaryColor: palette.text.white,
    },
    prevButton: {
      ...styles['prevButton'],
    },
    nextButton: {
      ...styles['nextButton'],
    },
    galleryButton: {
      primaryColor: palette.secondary.xdark,
      secondaryColor: palette.text.white,
      style: {
        visibility: props.showGalleryButton ?? true ? 'visible' : 'hidden',
      } as CSSProperties,
    },
    cameraButton: {
      style: {
        visibility: props.captureMode && (props.showCaptureButton ?? true) ? 'visible' : 'hidden',
      } as CSSProperties,
    },
  };
}
