import { useTranslation } from 'react-i18next';
import { ActionIcon, Table, Button, Menu } from '@mantine/core';
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons-react';
import styles from './InteriorDamageTable.module.css';

interface InteriorDamage {
  area: string;
  damage_type: string;
  repair_cost: number | null;
}

export interface InteriorDamageTableProps {
  currency: string;
  damages?: InteriorDamage[];
  onAddDamage?: () => void;
  onEdit?: (index: number) => void;
  onDelete?: (index: number) => void;
}

export function InteriorDamageTable({
  currency,
  damages,
  onAddDamage,
  onEdit = () => {},
  onDelete = () => {},
}: InteriorDamageTableProps) {
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      <Table verticalSpacing='md' horizontalSpacing='md' highlightOnHover withBorder>
        <thead>
          <tr>
            <th>
              <div className={styles['thContent']}>{t('inspectionReview.table.area')}</div>
            </th>
            <th>
              <div className={styles['thContent']}>{t('inspectionReview.table.damageTypes')}</div>
            </th>
            <th>
              <div className={styles['thContent']}>{t('inspectionReview.table.deduction')}</div>
            </th>
            <th className={styles['actionIconTh']}></th>
          </tr>
        </thead>
        <tbody className={styles['tbody']}>
          {damages?.map((damage, index) => (
            <tr key={index}>
              <td>{damage.area}</td>
              <td>{damage.damage_type}</td>
              <td>
                {currency === '$' && `${currency} `}
                {damage.repair_cost}
                {currency !== '$' && ` ${currency}`}
              </td>
              <td className={styles['actionIconTd']}>
                <Menu shadow='md'>
                  <Menu.Target>
                    <ActionIcon variant='subtle' color='dark'>
                      <IconDotsVertical size={14} />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item onClick={() => onEdit(index)} icon={<IconPencil />}>
                      {t('inspectionReview.edit')}
                    </Menu.Item>
                    <Menu.Item color='red' onClick={() => onDelete(index)} icon={<IconTrash />}>
                      {t('inspectionReview.delete')}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className={styles['addDamageContainer']}>
        <Button
          className={styles['addDamageButton']}
          uppercase
          size='md'
          variant='default'
          onClick={onAddDamage}
        >
          {t('inspectionReview.damageManipulator.addDamage')}
        </Button>
      </div>
    </div>
  );
}
