import {
  PartSelectionOrientation,
  VehicleModel,
  VehiclePart,
  VehicleType,
} from '@monkvision/types';
import { SVGProps } from 'react';
import { partSelectionWireframes, vehicles } from '@monkvision/sights';
import { DynamicSVG, DynamicSVGCustomizationFunctions } from '@monkvision/common-ui-web';
import { useMonkTheme } from '@monkvision/common';
import { styles } from './Vehicle360Wireframe.style';
import { PRICING_LEGENDS } from '../../../hooks/useInspectionReview';

/**
 * Props accepted by the VehicleDynamicWireframe component.
 */
export interface Vehicle360WireframeProps {
  /**
   * Vehicle type to display the wireframe for.
   */
  vehicleType: VehicleType;
  priceByPart?: { part: VehiclePart; pricing: number; isPriced: boolean }[];
  validedParts?: VehiclePart[];
  /**
   * The orientation of the wireframe.
   *
   * @default PartSelectionOrientation.FRONT_LEFT
   */
  orientation?: PartSelectionOrientation;
  /**
   * Callback when the user clicks a part.
   */
  onClickPart?: (parts: VehiclePart) => void;
  /**
   * Callback used to customize the display style of each vehicle part on the wireframe.
   * See `DynamicSVGCustomizationFunctions` for more details.
   *
   * @see DynamicSVGCustomizationFunctions
   */
  getPartAttributes?: (part: VehiclePart) => SVGProps<SVGElement>;
}

function isPricingPill(pillId: SVGElement) {
  // return pillId && pillId.id.startsWith('damage-pill-pricing_');
  return pillId && pillId.id.startsWith('damage-pill_');
}

function isPartValidated(element: SVGElement, parts?: VehiclePart[]) {
  // return element.id && element.id.includes('door_front_left');
  return element.id && parts?.some((part) => element.id.includes(part));
}

function isCarPartElement(element: SVGElement) {
  return element.id !== '' && element.classList.contains('car-part');
}

function getPillPart(elementId: string) {
  return elementId.substring(12);
}
function createGetAttributesCallback(
  onClickPart: NonNullable<Vehicle360WireframeProps['onClickPart']>,
  getPartAttributes: NonNullable<Vehicle360WireframeProps['getPartAttributes']>,
  priceByPart?: { part: VehiclePart; pricing: number; isPriced: boolean }[],
  validedParts?: VehiclePart[],
): NonNullable<DynamicSVGCustomizationFunctions['getAttributes']> {
  return (element, groups) => {
    const groupElement: SVGGElement | undefined = groups[0];
    let part: VehiclePart;
    if (groupElement && isCarPartElement(groupElement)) {
      part = groupElement.id as VehiclePart;
    } else if (isCarPartElement(element)) {
      part = element.id as VehiclePart;
    } else if (isPricingPill(groupElement) || isPricingPill(element)) {
      if (isPartValidated(element, validedParts) || element.classList.contains('severity-none')) {
        if (groupElement) {
          part = getPillPart(groupElement.id) as VehiclePart;
          const attributes = getPartAttributes(part);
          attributes.onClick = () => onClickPart(part);
          attributes.style = { display: 'flow' };
          if (element.classList.contains('severity-none')) {
            attributes.style = { ...attributes.style, fill: 'green' };
          }
          return attributes;
        }
        return { style: { display: 'flow' } };
      }
      return { style: { display: 'none' } };
    } else {
      return { style: styles['notCarPart'] };
    }
    const attributes = getPartAttributes(part);
    if (element.tagName === 'g') {
      delete attributes.style;
    }
    const price = priceByPart?.find((p) => p.part === part);
    // if (part.includes('wheel') && price?.isPriced) {
    if (price?.isPriced) {
      attributes.style = { ...attributes.style, fill: PRICING_LEGENDS.tire.color };
    }
    if (
      price &&
      price.pricing >= PRICING_LEGENDS.low.min &&
      price.pricing < PRICING_LEGENDS.low.max
    ) {
      attributes.style = { ...attributes.style, fill: PRICING_LEGENDS.low.color };
    }
    if (
      price &&
      price.pricing >= PRICING_LEGENDS.mid.min &&
      price.pricing < PRICING_LEGENDS.mid.max
    ) {
      attributes.style = { ...attributes.style, fill: PRICING_LEGENDS.mid.color };
    }
    if (price && price.pricing >= PRICING_LEGENDS.high.min) {
      attributes.style = { ...attributes.style, fill: PRICING_LEGENDS.high.color };
    }
    if (element.classList.contains('selectable') && element.id) {
      attributes.onClick = () => onClickPart(part);
      attributes.style = { ...attributes.style, ...styles['selectable'] };
    }
    return attributes;
  };
}

function getVehicleModel(vehicleType: VehicleType): VehicleModel {
  const detail = Object.entries(vehicles)
    .filter(([type]) => type !== VehicleModel.AUDIA7)
    .find(([, details]) => details.type === vehicleType)?.[1];
  if (detail === undefined) {
    throw new Error(`No vehicle model found for vehicle type ${vehicleType}`);
  }
  return detail.id;
}

/**
 * Component that displays a dynamic wireframe of a vehicle, allowing the user to select parts of the vehicle.
 */
export function Vehicle360Wireframe({
  vehicleType,
  orientation = PartSelectionOrientation.FRONT_LEFT,
  priceByPart,
  validedParts,
  onClickPart = () => {},
  getPartAttributes = () => ({}),
}: Vehicle360WireframeProps) {
  const wireframes = partSelectionWireframes[getVehicleModel(vehicleType)];
  if (wireframes === undefined) {
    throw new Error(`No wireframe found for vehicle type ${vehicleType}`);
  }
  const overlay = wireframes[orientation];
  const { utils } = useMonkTheme();

  return (
    <DynamicSVG
      svg={overlay}
      style={{
        width: '100%',
        color: utils.getColor('text-primary'),
      }}
      getAttributes={createGetAttributesCallback(
        onClickPart,
        getPartAttributes,
        priceByPart,
        validedParts,
      )}
    />
  );
}
