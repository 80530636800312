import { Button } from '@mantine/core';
import { useMonkTheme } from '@monkvision/common';
import { IconCheck } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import styles from './TabsSwitch.module.css';

export enum ViewMode {
  INTERIOR = 'interior',
  EXTERIOR = 'exterior',
  INTERIOR_FORM = 'interior-form',
}

export interface TabsSwitchProps {
  mode?: ViewMode;
  onTabChange?: (mode: ViewMode) => void;
}

export function TabsSwitch({ mode = ViewMode.EXTERIOR, onTabChange = () => {} }: TabsSwitchProps) {
  const { palette } = useMonkTheme();
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      <Button
        className={styles['exteriorButton']}
        onClick={() => onTabChange(ViewMode.EXTERIOR)}
        variant='default'
        size='lg'
        style={{ backgroundColor: mode === ViewMode.EXTERIOR ? palette.background.dark : '' }}
        leftIcon={mode === ViewMode.EXTERIOR && <IconCheck size='24px' />}
      >
        {t('inspectionReview.exterior')}
      </Button>
      <Button
        className={styles['interiorButton']}
        onClick={() => onTabChange(ViewMode.INTERIOR)}
        variant='default'
        color='red'
        size='lg'
        style={{
          backgroundColor: [ViewMode.INTERIOR_FORM, ViewMode.INTERIOR].includes(mode)
            ? palette.background.dark
            : '',
        }}
        leftIcon={
          [ViewMode.INTERIOR_FORM, ViewMode.INTERIOR].includes(mode) && <IconCheck size='24px' />
        }
      >
        {t('inspectionReview.interiorTire')}
      </Button>
    </div>
  );
}
