import { useLoadingState, useObjectTranslation } from '@monkvision/common';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { VehiclePart } from '@monkvision/types';
import dayjs from 'dayjs';
import { unparse } from 'papaparse';
import {
  TeslaDeductionDetails,
  TeslaInspection,
  TeslaInteriorDamages,
} from '../useTeslaInspectionList/types';
import { formatPrice } from '../../utils';

export type TeslaDeductionDetailsRecord = Partial<
  | Record<VehiclePart, Pick<TeslaDeductionDetails, 'pricing' | 'damages'>>
  | Record<string, Pick<TeslaInteriorDamages, 'pricing' | 'damages'>>
>;

export interface UseExportToCsvParams {
  inspections: TeslaInspection[];
  currency: string;
}

function getDeductionDetailsRecord(
  deductionDetails?: TeslaDeductionDetails[],
  interiorDeductionDetails?: TeslaInteriorDamages[],
): TeslaDeductionDetailsRecord | undefined {
  if (!Array.isArray(deductionDetails)) {
    return undefined;
  }

  const exterior = deductionDetails.reduce(
    (prev, { part, pricing, damages }) => ({ ...prev, [part]: { pricing, damages } }),
    {},
  );
  const interior = interiorDeductionDetails?.reduce(
    (prev, { area, pricing, damages }) => ({ ...prev, [area]: { pricing, damages } }),
    {},
  );
  return { ...exterior, ...interior } as TeslaDeductionDetailsRecord;
}

export function useExportToCsv({ inspections, currency }: UseExportToCsvParams) {
  const { i18n } = useTranslation();
  const { tObj } = useObjectTranslation();
  const loading = useLoadingState();

  const createCsv = useCallback(() => {
    const csvArray: string[][] = [];
    csvArray.push([
      'VIN',
      'Country',
      'Licence Plate',
      'Lease Maturity Date',
      'Model',
      'Odometer',
      'Mileage Unit',
      'Status',
      'ID',
      'Total Deductions',
      'Deduction Details',
      'Original Deduction Details',
      'Last Updated',
      'Inspection Start',
      'Inspection End',
    ]);
    inspections.forEach((inspection) => {
      const deductionDetails =
        JSON.stringify(
          getDeductionDetailsRecord(inspection.deductionDetails, inspection.interiorDamages),
        ) ?? '{}';
      const originalDeductionDetails =
        JSON.stringify(getDeductionDetailsRecord(inspection.originalDeductionDetails)) ??
        deductionDetails;
      csvArray.push([
        inspection.vin ?? '',
        inspection.country ?? '',
        inspection.licencePlate ?? '',
        inspection.leaseMaturityDate
          ? dayjs(inspection.leaseMaturityDate).format('YYYY-MM-DD')
          : '',
        inspection.model ?? '',
        inspection.odometer?.toString() ?? '',
        inspection.mileageUnit ?? '',
        inspection.status ?? '',
        inspection.inspectionId ?? '',
        inspection.totalPricing
          ? formatPrice(inspection.totalPricing, currency, i18n.language)
          : '',
        deductionDetails,
        originalDeductionDetails,
        inspection.lastUpdated
          ? dayjs(inspection.leaseMaturityDate).format('YYYY-MM-DD HH:mm:ss')
          : '',
        inspection.inspectionStart
          ? dayjs(inspection.leaseMaturityDate).format('YYYY-MM-DD HH:mm:ss')
          : '',
        inspection.inspectionEnd
          ? dayjs(inspection.leaseMaturityDate).format('YYYY-MM-DD HH:mm:ss')
          : '',
      ]);
    });
    return unparse(csvArray);
  }, [inspections, currency, tObj, i18n.language]);

  const exportToCsv = useCallback(() => {
    loading.start();
    const csv = createCsv();
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'tesla_inspections.csv';
    a.click();
    a.remove();
    loading.onSuccess();
  }, [loading, inspections, createCsv]);

  return { exportToCsv, loading };
}
