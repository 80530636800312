import { Outlet, useNavigate } from 'react-router-dom';
import { getEnvOrThrow, MonkProvider } from '@monkvision/common';
import { useTranslation } from 'react-i18next';
import { CaptureAppConfig } from '@monkvision/types';
import { LiveConfigAppProvider } from '@monkvision/common-ui-web';
import { Page } from '../pages';
import * as localConfigEu from '../local-config-eu.json';
import * as localConfigUs from '../local-config-us.json';
import { AppContainer } from './AppContainer';
import { AppRegion, useAppRegion } from '../contexts';

const LIVE_CONFIG_ID_EU = getEnvOrThrow('REACT_APP_LIVE_CONFIG_ID_EU');
const LIVE_CONFIG_ID_US = getEnvOrThrow('REACT_APP_LIVE_CONFIG_ID_US');

function getLocalConfigProp(region: AppRegion | null): CaptureAppConfig | undefined {
  if (process.env['REACT_APP_USE_LOCAL_CONFIG'] !== 'true') {
    return undefined;
  }
  if (region === AppRegion.US) {
    return localConfigUs as CaptureAppConfig;
  }
  return localConfigEu as CaptureAppConfig;
}

function getLiveConfigId(region: AppRegion | null): string {
  if (region === AppRegion.US) {
    return LIVE_CONFIG_ID_US;
  }
  return LIVE_CONFIG_ID_EU;
}

export function App() {
  const { region } = useAppRegion();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return (
    <LiveConfigAppProvider
      id={getLiveConfigId(region)}
      localConfig={getLocalConfigProp(region)}
      onFetchAuthToken={() => navigate(Page.DASHBOARD)}
      onFetchLanguage={(lang) => i18n.changeLanguage(lang)}
      lang={i18n.language}
    >
      <MonkProvider>
        <AppContainer>
          <Outlet />
        </AppContainer>
      </MonkProvider>
    </LiveConfigAppProvider>
  );
}
