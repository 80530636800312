import { CreateTeslaInspectionParams } from './types';
import { TeslaCountry, TeslaModel } from '../useTeslaInspectionList/types';

export function getPricingOutputFormat(params: CreateTeslaInspectionParams): string {
  if (params.country === TeslaCountry.US) {
    return 'tesla_us';
  }
  return 'tesla_eu';
}

export function getPricingConfig(params: CreateTeslaInspectionParams): string {
  let config = '';
  switch (params.country) {
    case TeslaCountry.DE:
      config += 'de_';
      break;
    case TeslaCountry.FR:
      config += 'de_';
      break;
    case TeslaCountry.NL:
      config += 'de_';
      break;
    case TeslaCountry.US:
      config += 'us_';
      break;
    case TeslaCountry.UK:
      config += 'uk_';
      break;
    default:
      throw new Error(`Unknown Tesla Country : ${params.country}`);
  }
  switch (params.model) {
    case TeslaModel.MODEL_3:
      config += '3';
      break;
    case TeslaModel.MODEL_S:
      config += 's';
      break;
    case TeslaModel.MODEL_X:
      config += 'x';
      break;
    case TeslaModel.MODEL_Y:
      config += 'y';
      break;
    default:
      throw new Error(`Unknown Tesla Model : ${params.model}`);
  }
  return config;
}
