import ReactDOM from 'react-dom';
import { MonitoringProvider } from '@monkvision/monitoring';
import { AnalyticsProvider } from '@monkvision/analytics';
import { MantineProvider } from '@mantine/core';
import { sentryMonitoringAdapter } from './sentry';
import { posthogAnalyticsAdapter } from './posthog';
import { AppRouter } from './app';
import { mantineLightTheme } from './theme';
import { AppRegionProvider } from './contexts';
import { AuthProvider } from './auth';
import './i18n';
import './index.css';

ReactDOM.render(
  <MonitoringProvider adapter={sentryMonitoringAdapter}>
    <AnalyticsProvider adapter={posthogAnalyticsAdapter}>
      <MantineProvider theme={mantineLightTheme}>
        <AppRegionProvider>
          <AuthProvider>
            <AppRouter />
          </AuthProvider>
        </AppRegionProvider>
      </MantineProvider>
    </AnalyticsProvider>
  </MonitoringProvider>,
  document.getElementById('root'),
);
