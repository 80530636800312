import { DamageType, VehiclePart } from '@monkvision/types';

export interface InteriorDamage {
  area: string;
  damage_type: string;
  repair_cost: number | null;
  index?: number;
}

export interface PartDetails {
  part: VehiclePart;
  isDamaged: boolean;
  damagesType: DamageType[];
  pricing?: number;
  isPriced?: boolean;
}
export interface TeslaOtherDamages {
  area: string;
  damage_type: string;
  repair_cost: number;
}

export enum PricingColors {
  NONE = 'lightgray',
  LOW = '#62b0ef',
  MID = '#e1a25b',
  HIGH = '#f49796',
}

export enum PricingLevels {
  TIRE = 'tire',
  LOW = 'low',
  MID = 'mid',
  HIGH = 'high',
}

export interface PricingLegends {
  color: PricingColors;
  min: number;
  max: number;
}

export const PRICING_LEGENDS: Record<PricingLevels, PricingLegends> = {
  [PricingLevels.TIRE]: {
    color: PricingColors.NONE,
    min: 0,
    max: 0,
  },
  [PricingLevels.LOW]: {
    color: PricingColors.LOW,
    min: 1,
    max: 300,
  },
  [PricingLevels.MID]: {
    color: PricingColors.MID,
    min: 300,
    max: 600,
  },
  [PricingLevels.HIGH]: {
    color: PricingColors.HIGH,
    min: 600,
    max: 999999,
  },
};

export const TESLA_WHEEL_FRONT_LEFT = [VehiclePart.RIM_FRONT_LEFT, VehiclePart.HUBCAP_FRONT_LEFT];

export const TESLA_WHEEL_FRONT_RIGHT = [
  VehiclePart.RIM_FRONT_RIGHT,
  VehiclePart.HUBCAP_FRONT_RIGHT,
];

export const TESLA_WHEEL_BACK_LEFT = [VehiclePart.RIM_BACK_LEFT, VehiclePart.HUBCAP_BACK_LEFT];

export const TESLA_WHEEL_BACK_RIGHT = [VehiclePart.RIM_BACK_RIGHT, VehiclePart.HUBCAP_BACK_RIGHT];
