import { Styles } from '@monkvision/types';

export const SMALL_WIDTH_BREAKPOINT = 700;

export const styles: Styles = {
  mainContainer: {
    width: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'space-between',
    inset: 0,
    position: 'relative',
    overflow: 'hidden',
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  overlay: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  topContainer: {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftContainer: {
    zIndex: 9,
    padding: 8,
  },
  overlayContainer: {
    zIndex: 8,
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    bottom: '15px',
  },
  rightContainer: {
    zIndex: 9,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: '10px 10px',
  },
  prevButton: {
    position: 'absolute',
    left: '20%',
    width: '40px',
    height: '40px',
    backgroundColor: 'transparent',
    border: 'solid 2px',
  },
  nextButton: {
    position: 'absolute',
    right: '20%',
    width: '40px',
    height: '40px',
    backgroundColor: 'transparent',
    border: 'solid 2px',
  },
};
