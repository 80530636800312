import { useCallback } from 'react';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { useMonkAppState } from '@monkvision/common';
import { useMonkApi } from '@monkvision/network';
import { TeslaPdf } from '../../components/TeslaPdf';
import { generateTeslaPdfInspectionData } from './generatePdfInspectionData';
import { getInspectionDisplayLocales } from '../../utils';

export interface UseTeslaPdfGeneratorParams {
  openInNewTab: boolean;
}

export function useTeslaPdfGenerator({ openInNewTab }: UseTeslaPdfGeneratorParams) {
  const { config, authToken } = useMonkAppState();
  const { getInspection, uploadPdf } = useMonkApi({
    authToken: authToken ?? '',
    apiDomain: config.apiDomain,
    thumbnailDomain: config.thumbnailDomain,
  });

  const generateAndDownloadPdf = useCallback(
    async (id: string) => {
      const { entities } = await getInspection({ id });
      const inspection = entities.inspections.find((i) => i.id === id);
      if (!inspection) {
        throw new Error('Inspection in PDF is not defined.');
      }
      const data = await generateTeslaPdfInspectionData(inspection, entities);
      const { lang, currency } = getInspectionDisplayLocales(inspection);
      const pdfDocument = pdf(<TeslaPdf data={data} lang={lang} currency={currency} />);
      const blob = await pdfDocument.toBlob();
      await uploadPdf({ id, pdf: blob });
      if (openInNewTab) {
        window.open(URL.createObjectURL(blob));
      } else {
        const fileName = `condition-report-${id}.pdf`;
        saveAs(blob, fileName);
      }
    },
    [getInspection, openInNewTab],
  );

  return { generateAndDownloadPdf };
}
